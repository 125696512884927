// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";
import "slick-carousel";

// custom

jQuery(document).ready(function () {

    jQuery("#home-banner-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
        speed: 1000,
        arrows: true,
        fade: true,
        autoplay: true,
    });

    // Dropdown - search the catalog
    jQuery("input#search").on("keypress", function (e) {
        if (e.which == 13) {
            jQuery("button#find-it-btn").trigger("click");
        }
    });

    jQuery("button#find-it-btn").on("click", function () {
        const search_link = jQuery("#search_link").attr("data-link");
        const search_key = jQuery("#search_key").attr("data-key");
        const search_type = jQuery("#search_type").val();
        const search = jQuery("#search").val();
        if (search_type != "" && search != "") {
            if (search_type == "catalog") {
                const url = `${search_link}?${search_key}=${search}`;
                window.open(url, "_blank");
            } else {
                const url = `https://orangecitylibrary.myweblinx.net/?s=${search}`;
                window.open(url, "_self");
            }
        }
        return false;
    });

    jQuery(".burger_menu").on("click", function () {
        jQuery(this).toggleClass("is-active");
    });

    jQuery(".burger_menu").on("click", function () {
        jQuery("body").toggleClass("overlay_box");
        jQuery(".main-navigation").toggleClass("intro_menu");
    });

    jQuery(".menu-item-has-children").prepend("<span class='opner'></span>");
    jQuery(".opner").on("click", function () {
        jQuery(this).parent("li.menu-item-has-children").toggleClass("open_submenu");
    });

    jQuery(".close_menu").on("click", function () {
        jQuery(".main-navigation").removeClass("intro_menu");
        jQuery(".hamburger.burger_menu").removeClass("is-active");
    });

    // mobile menu js
    jQuery(".library_btn").on("click", function () {
        jQuery(".hrours_dropdown .select_text").slideToggle();
    });
    jQuery(".hrours_dropdown .select_text li").on("click", function () {
        jQuery(".hrours_dropdown .select_text").slideUp();
    });
    jQuery("header .header-top .header-top-left .hrours_dropdown .select_text li:first-child").addClass("active");
    jQuery(".library_btn").text(jQuery("header .header-top .header-top-left .hrours_dropdown .select_text li.active").text());

    const newOptions = jQuery(".hrours_dropdown .select_text li");

    newOptions.on("click", function () {
        jQuery(".library_btn").text(jQuery(this).text());

    });

    jQuery("header .header-top .header-top-left .hrours_dropdown .select_text li").on("click", function () {
        jQuery(this).addClass("active");
    });

    // slick dot js
    if (jQuery(".hero_banner_section .slick-dots li").length <= 1) {
        jQuery(".hero_banner_section .slick-dots").addClass("dont_display");
    }

    // dropdown js
    jQuery(".custom-select").on("click", function () {
        jQuery(".custom-select").toggleClass("visible-header");
    });

    jQuery(document).on("click", function (event) {
        if (!jQuery(event.target).closest(".custom-select").length) {
            jQuery(".custom-select").removeClass("visible-header");
        }
    });

    // ninjs form input js

});


/* Reading Tabs */

function initTabs() {
    if (jQuery("#recommended-tabs").length > 0) {
        jQuery("#recommended-tabs").tabs();
    }

    if (window.innerWidth < 767) {
        jQuery(".recommended-reading .ui-tabs ").prepend("<span class='recomanded_dropmenu'></span>");
        jQuery(".recomanded_dropmenu").on("click", function () {
            jQuery(".ui-tabs-nav").slideToggle();
        });

        jQuery(".recommended-reading div#recommended-tabs ul.ui-tabs-nav li a span").on("click", function () {
            jQuery(".ui-tabs-nav").slideUp();
        });

        jQuery(".recomanded_dropmenu").text(jQuery(".recommended-reading div#recommended-tabs ul .ui-state-active a span").text());
        jQuery(".recommended-reading div#recommended-tabs ul li a span").on("click", function () {
            jQuery(".recomanded_dropmenu").text(jQuery(this).text());

        });
    }
}

jQuery(document).ready(function () {
    initTabs();
});
/* end reading tabs */

jQuery(document).on("click touchstart", function (event) {
    if (jQuery(event.target).parents(".header-bottom").length < 1 && !jQuery(event.target).hasClass("header-bottom")) {
        jQuery(".main-navigation").removeClass("intro_menu");
        jQuery(".burger_menu").removeClass("is-active");
    }
});
